import * as React from 'react'
import { Link } from 'gatsby'
import newtab from 'utils/newtab'

const CTALink = ({ label, url, className = 'action lg', children, style }) => {
  if (!url || (!children && !label)) return null

  const external = /^\/files\//g.test(url) || url.startsWith('http')

  const Content = () => <>
    {children || label}
    <span className="arrow">➞</span>
  </>

  return (
    <p style={style}>
      {external
        ?  (
          <a className={className} href={url} {...newtab}>
            <Content/>
          </a>
        ) : (
          <Link className={className} to={url}>
            <Content/>
          </Link>
        )
      }
    </p>
  )
}

export default CTALink
