import * as React from 'react'
import * as css from './CaseStudyPreview.module.css'
import { Link } from 'gatsby'
import Img from 'components/common/Img'

const CaseStudyPreview = ({ data: { title, featuredImage, seo }, large }) => (
  <article className="anim-fadein">
    <Link className={css.link} to={`/case-studies/${seo.slug.current}`}>
      {!!featuredImage
        ? <Img className={css.img} image={featuredImage} alt={title} />
        : <div className={css.placeholder} />
      }

      <div className={`${large ? 'h1' : 'h3'}`}>{title}</div>

      {!!seo.description && (
        <p className={css.desc}>{seo.description}</p>
      )}

      <p className={`${css.cta} link`}>Read Case Study ➞</p>
    </Link>
  </article>
)

export default CaseStudyPreview
