import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import CaseStudyPreview from 'components/case-studies/CaseStudyPreview'
import TextWithBg from 'components/common/TextWithBg'

const meta = {
  title: 'FASTECH Case Studies',
}

const CaseStudies = () => {
  const {
    allSanityCaseStudyPg,
    img
  } = useStaticQuery(query)

  return (
    <DefaultLayout {...meta}>
      <div className="ctnr padding">
        <h1 className="h1 technical mb-8">{meta.title}</h1>

        <div className="grid gap-y-16 gap-x-8 md:grid-cols-3">
          {allSanityCaseStudyPg.nodes?.map((data, key) => (
            <CaseStudyPreview data={data} key={key} />
          ))}
        </div>
      </div>

      <TextWithBg
        image={img}
        title="Start Designing Your Energy Infrastructure Project"
        description="Are you looking for a trusted partner to manage your upcoming energy project, facility upgrade or ongoing maintenance? Let’s work together."
        link={{
          label: 'Get started',
          url: '/contact',
        }}
      />
    </DefaultLayout>
  )
}

export default CaseStudies

const query = graphql`query CaseStudies {
  allSanityCaseStudyPg(sort: {fields: orderRank}) {
    nodes {
      title
      featuredImage {
        asset { gatsbyImageData(placeholder: NONE, aspectRatio: 1.5) }
      }
      seo {
        description
        slug { current }
      }
    }
  }
  img: file(relativePath: {eq: "cta/bottom-cta.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE)
    }
  }
}`
